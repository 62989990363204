export class LocalStorageProvider {
  static setUserId(user) {
    localStorage.setItem('userId', user);
  };

  static getUserId() {
    return localStorage.getItem('userId');
  };

  static removeUserId() {
    localStorage.removeItem('userId');
  };

  static setAccessToken(token) {
    localStorage.setItem('request-token', token);
  };

  static getAccessToken() {
    return localStorage.getItem('request-token');
  };

  static removeAccessToken() {
    localStorage.removeItem('request-token');
  };

  static setRedirectURI(uri) {
    localStorage.setItem('redirect-uri', uri);
  };

  static getRedirectURI() {
    return localStorage.getItem('redirect-uri');
  };

  static removeRedirectURI() {
    localStorage.removeItem('redirect-uri');
  };

  static setStateKey(state) {
    localStorage.setItem('state', state);
  };

  static getStateKey() {
    return localStorage.getItem('state');
  };

  static removeStateKey() {
    localStorage.removeItem('state');
  };
}
