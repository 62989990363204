import { useState } from 'react';
import axios from "axios";

import toast from 'react-hot-toast';
import { LocalStorageProvider } from 'shared/local-storage-provider';

const BASE_API_URL      = window.REACT_APP_BASE_API_URL || "https://abco-api-authorisation.sdb.tarabutgateway.io";
const AUTHORISE_CONSENT = "/consent/api/v1";
const VERIFY_OTP        = "/authentication/api/v1/verify-otp";

// const BASE_API_URL = "https://run.mocky.io/v3";
// const AUTHORISE_CONSENT = "/95a1766f-8fe2-463d-8a3e-77f9eb019155";  //200
// const VERIFY_OTP = "/e8350641-24ca-44d1-b8db-23d5ef3ac14f";  //200 LARGE DATA

const statusCheck = (status) => {
  if (String(status).match(/^2/g)) {
    if (status == 200) {
      let message = "Request successful"
      console.log(message)
    } else {
      let message = "Request successful. But data received is not correct"
      console.log(message)
    }
  } else if(String(status).match(/^3/g)) {
    let message = "Received 3XX error. Requires further action"
    console.log(message)
    toast.error(message);
  } else if(String(status).match(/^4/g)) {
    let message = "Received 4XX error. Please check the request again"
    console.log(message)
    toast.error(message);
  } else {
    let message = "Received 5XX error. Server has failed in completing the request."
    console.log(message)
    toast.error(message);
  }
}

const headers = {
  'accept': 'application/json',
  'Content-Type': 'application/json',
}

export class AuthService {
  static verify_otp = (formData) => {
    LocalStorageProvider.setUserId(formData.cpr);
    return axios
      .post(BASE_API_URL + VERIFY_OTP, {
        userId: formData.cpr,
        otp: formData.otp,
        requestToken: formData.requestToken
      }, { headers: headers })
      .then(response => {
        statusCheck(response.status)
        return response.data
      })
      .catch(function (error) {
        console.log("\nError occurred while verifying OTP", error);
      });
  }

  static authorise_consent = (formData) => {
    return axios
      .post(BASE_API_URL + AUTHORISE_CONSENT, {
        "userId": LocalStorageProvider.getUserId(),
        "selectAccounts": formData.accounts,
        "requestToken": formData.requestToken,
        "interactionId": formData.interactionID
      }, { headers: headers })
      .then(response => {
        statusCheck(response.status)
        return response.data
      })
      .catch(function (error) {
        console.log("\nError occurred while authorising consent", error);
      });
  }

  static logout = () => {
    LocalStorageProvider.removeAccessToken();
    LocalStorageProvider.removeStateKey();
    LocalStorageProvider.removeUserId();
  }
}
