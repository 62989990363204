import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocalStorageProvider } from 'shared/local-storage-provider';

import { Loader } from 'components/global/loader';
import toast from 'react-hot-toast';
import { AuthService } from 'shared/service/auth-service'
import { Validation } from 'utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes/route-path';
import { PersonalAccount } from './personal-account';
import { Grid } from '@tarabutgateway/component-library';
import {
    Container,
    Wrapper,
    StyledBox,
    Container,
    StyledTitle,
    StyledSubTitle,
    ProceedButton,
    DeclineButton,
    MiscInfo,
    StyledFlex,
    FooterContainer} from './accounts.styled';

export const AccountsPage = () => {

    var productName, formData = [];
    const navigate = useNavigate();
    const [selectedAccounts, setAccounts] = useState([]);
    const { state } = useLocation();

    const updateAccountsIDs = (id, type) => {
        // add the selected account to array
        if (type == 'add') {
            setAccounts([...selectedAccounts, id])
        } else {
            // remove the account on deselect
            setAccounts((current) => current.filter(
                (selectedAccounts) => selectedAccounts != id)
            )
        }
    }

    const validationSchema = yup.object({
        accounts: Validation.accounts.required(),
        interactionID: Validation.interactionID.required(),
        requestToken: Validation.requestToken.required()
    });

    const {
        handleSubmit,
        setValue,
        formState: {errors, isValid, isSubmitting}
      } = useForm({
        defaultValues: {
            accounts: {},
            interactionID: "",
            requestToken: ""
        },
        resolver: async (data, context, options) => {
          // for debugging validation schema
          // console.log('validation result', await yupResolver(validationSchema)(data, context, options))
          return yupResolver(validationSchema)(data, context, options)
        }
      });

    const onError = () => toast.error("Form Submission Failure");

    const onSubmit = async (data) => {
        const response = await AuthService.authorise_consent(data);
        if (response) {
            if (response.hasOwnProperty("status")) {
                toast.error("Incorrect API response");
                navigate(RoutePath.transition, { state: { response, "status": "failed" }} )
            } else {
                toast.success("Consent request submitted");
                navigate(RoutePath.transition, { state: { response, "status": "approved" }} )
            }
        }
      };

    const goToRedirectURI = () => {
        const redirectURL = LocalStorageProvider.getRedirectURI();
        const redirectTo = "?status=cancelled&state=" + LocalStorageProvider.getStateKey();
        window.location.replace(redirectURL+redirectTo);
    }

    useEffect(()=>{
        let response = state.response.accounts
        let interactionID = state.response.interactionId
        selectedAccounts.forEach(
            (val, index) => {
                formData.push.apply(formData, [response.find(item => item.id === `${val}`)]);
            }
        );
        setValue("accounts", formData, {
            shouldValidate: true,
            shouldDirty: true
        })
        setValue("requestToken", LocalStorageProvider.getAccessToken(), {
            shouldValidate: true,
            shouldDirty: true
        })
        setValue("interactionID", interactionID, {
            shouldValidate: true,
            shouldDirty: true
        })
    }, [selectedAccounts]);

    return (
        <>
        {isSubmitting && <Loader />}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Container>
                <Wrapper>
                    <Grid.Item span={12} bpSm={12} bpLg={12}>
                      <StyledTitle>Select your account</StyledTitle>
                    </Grid.Item>
                    <Grid.Item span={12} bpSm={12} bpLg={12}>
                        <StyledSubTitle>Tarabut Gateway is requesting permision to access your ila Bank account</StyledSubTitle>
                    </Grid.Item>
                    <StyledBox flex={6} flexDirection="column" display="flex">
                        <div>
                            { state?.response?.accounts.length ?
                                (
                                state.response.accounts.map(
                                (array) => {
                                    array?.id.match(/TD|PL|SP$/) ?
                                        productName = array.accountNickname :
                                            productName = array.productName

                                        return <PersonalAccount key={array.id}
                                                                accountId={array.id}
                                                                selected={false}
                                                                productName={productName}
                                                                accountNumber={array.accountNumber}
                                                                updateSelection={updateAccountsIDs}
                                                                unit={array.additionalAttributes.unit}/>
                                        }
                                    )
                                )
                                :
                                <Grid.Item span={12} bpSm={12} bpLg={12}>
                                    <StyledTitle>No accounts exist for this user</StyledTitle>
                                </Grid.Item> }
                        </div>
                    </StyledBox>



                </Wrapper>
                    <StyledFlex align="center">
                        <MiscInfo>{ state.response.tppName.length && state.response.tppName } will access your information from your account(s)</MiscInfo>
                    </StyledFlex>
                    <FooterContainer>
                        <Grid.Item span={12} bpSm={12} bpLg={12}>
                            <ProceedButton enabled={ selectedAccounts.length ? 'true' : 'false' } type="submit">Proceed</ProceedButton>
                        </Grid.Item>
                    </FooterContainer>
                    <FooterContainer>
                        <Grid.Item span={12} bpSm={12} bpLg={12}>
                            <DeclineButton onClick={goToRedirectURI}>Decline</DeclineButton>
                        </Grid.Item>
                    </FooterContainer>
            </Container>
        </form>
        </>
    );
};