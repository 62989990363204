import styled from 'styled-components';

import { pxToRem, mediaQuery } from '@tarabutgateway/component-library';

export const OuterContainer = styled.div`
  height: 100vh;
  width: ${pxToRem(375)};

  justify-content:center;
  align-items:center;
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;

  background: linear-gradient(290deg, #010d32 28.66%, rgb(2, 27, 88, 1) 99.74%);
  ${mediaQuery('max', 'md')} {
    width: 100vw;
  }
  ${mediaQuery('min', 'md')} {
    overflow-y: scroll;
  }
`;