import React, { useState, useEffect } from 'react';

import Checked from 'assets/images/checked.svg';
import Unchecked from 'assets/images/unchecked.svg';
import Ila from 'assets/images/accounts/ila.svg';
import Alburaq from 'assets/images/accounts/alburaq.svg';
import { Grid } from '@tarabutgateway/component-library';
import {
    TitleWrapper,
    SubTitleWrapper,
    StyledAccountName,
    StyledDiv,
    StyledIBAN,
    LogoContainer,
    LogoSrc} from './accounts.styled';

export const PersonalAccount = (props) => {
    const [boxState, updateBoxState] = useState(props.selected)
    let logoILA = true;
    const albUnit = new RegExp(/alburaq/i)

    const clickableBox = () => {
        updateBoxState(!boxState)
    }

    if (props.unit.match(albUnit)) {
        logoILA = false
    }

    // Update the array in parent component for selected accounts
    useEffect(() => {
        if (boxState) {
            props.updateSelection(props.accountId, 'add')
        } else {
            props.updateSelection(props.accountId, 'delete')
        }
    }, [boxState])

    return (
        <StyledDiv onClick={clickableBox}>
            <TitleWrapper>
                <Grid.Item span={1} bpSm={1} bpLg={1}>
                    <img src={boxState ? Checked : Unchecked} alt="account selection checkbox"/>
                </Grid.Item>
                <Grid.Item span={6} bpSm={6} bpLg={6}>
                    <StyledAccountName>{props.productName}</StyledAccountName>
                </Grid.Item>

                <LogoContainer>
                    <Grid.Item span={5} bpSm={5} bpLg={5}>
                        <LogoSrc src={logoILA ? Ila : Alburaq} alt="bank specific logo"/>
                    </Grid.Item>
                </LogoContainer>

            </TitleWrapper>
            <SubTitleWrapper>
                <Grid.Item span={1} bpSm={1} bpLg={1}></Grid.Item>
                <Grid.Item span={10} bpSm={10} bpLg={10}>
                    <StyledIBAN>{props.accountNumber}</StyledIBAN>
                </Grid.Item>
            </SubTitleWrapper>
        </StyledDiv>
    )
}