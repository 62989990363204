import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToRem } from '@tarabutgateway/component-library';

export const StyledHeader = styled.div`
  display: flex;
  margin: ${pxToRem(30)} auto ${pxToRem(20)} auto;
  padding-left: ${pxToRem(25)}
`;

export const CloseBtn = styled.div`
  display: flex;
  margin-right: ${pxToRem(10)}
`;