import PropTypes from 'prop-types';

import { RoutePath } from './route-path';

const { shape, string, elementType, bool } = PropTypes;

export const RouteConfig = shape({
  name: string.isRequired,
  path: RoutePath.isRequired,
  component: elementType.isRequired,
  isAuth: bool,
});
