import SyncLoader from "react-spinners/SyncLoader";
import { Grid } from '@tarabutgateway/component-library';

export const Loader = () => {
    // Spinner CSS
    const override = {
        position: "absolute",
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        top: "50vh",
    }
    return (
        <Grid displayFlex flexDirection="row">
            <Grid.Item span={12} bpSm={12} bpLg={12}>
                <SyncLoader
                    aria-label="Loading Spinner"
                    color="#7bee00"
                    cssOverride={override} />
            </Grid.Item>
        </Grid>
    )
}