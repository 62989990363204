import React, { useMemo } from 'react';

import Logo from 'assets/images/Logo.png';
import Cross from 'assets/images/cross.svg';
import { LocalStorageProvider } from 'shared/local-storage-provider';

import { Flex } from '@tarabutgateway/component-library';
import { useLocation } from 'react-router-dom';
import { StyledHeader, CloseBtn  } from './header.styled';

export const Header = () => {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const parseJWT = () => {
    let token, redirect;
    const queryParamToken = query.get("requestToken")
    token = (queryParamToken) ? queryParamToken : LocalStorageProvider.getAccessToken()
    try {
      if (token == "null") {
        throw new Error("Token missing");
      } else {
        const decodedJWT = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
        LocalStorageProvider.setRedirectURI(decodedJWT.redirect_uri)
        LocalStorageProvider.setStateKey(decodedJWT.state)
        return decodedJWT;
      }
    } catch(error) {
      console.log("Error Fetching Token::", error)
      LocalStorageProvider.setRedirectURI("https://www.google.com")
      LocalStorageProvider.setStateKey("")
      return {"redirect_uri": "https://www.google.com"}
    }
  }
  const redirectURL = parseJWT();
  const RedirectPage = () => {
    const redirURL = redirectURL.redirect_uri;
    const redirectTo = "?status=cancelled&state=" + redirectURL.state;

    window.location.replace(redirURL+redirectTo);
  }

  return (
      <Flex align="center" fullWidth mb={5}>
        <StyledHeader>
          <img src={Logo} alt="Ila Bank Logo"/>
        </StyledHeader>
        <CloseBtn>
          <a onClick={RedirectPage}><img src={Cross} alt="Close the app"/></a>
        </CloseBtn>
      </Flex>
  );
};
