import styled from 'styled-components';

import { pxToRem, Flex } from '@tarabutgateway/component-library';

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    width: 85%;
    margin-top: 25vh;
`;

export const StyledTitle = styled.p`
    font-family: 'Neo Sans Arabic';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
`;

export const StyledSubTitle = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #BDC7D2;
    margin-top: ${pxToRem(10)};
`;

export const IMGWrap = styled.img`
    padding: ${props => props.wider ? "0 10px 0 10px" : "0 5px 0 5px"};
`;

export const LogoContainer = styled(Flex)`
    margin-top:30px
`;