import styled, { css } from 'styled-components';

import { Box, pxToRem, mediaQuery } from '@tarabutgateway/component-library';

export const Container = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    width: 85%;
`;

export const LoginField = styled.input`
    width: 96%;
    height: ${pxToRem(40)};
    background: rgba(255, 255, 255, 0.18);
    border: ${pxToRem(1)} solid #66C7FF;
    border-radius: ${pxToRem(8)};

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
    color: #FFFFFF;

    outline: none;
    padding: 0 ${pxToRem(5)} 0 ${pxToRem(5)};
    border: ${props => props.error ? "1px solid #FF8088" : "1px solid rgba(255, 255, 255, 0.2)"};
    &:focus {
        border: ${props => props.error ? "1px solid #FF8088" : "1px solid #66C7FF"};
    }
`;

export const DirectionsTitle = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 200;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(10)};
    color: #FFFFFF;
    margin: ${pxToRem(10)} 0 ${pxToRem(14)} 0;
`;

export const DirectionsDetails = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(24)};
    color: #BDC7D2;
    margin-left: ${pxToRem(5)}
`;

export const Placeholder = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 200;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(6)};
    color: #F2F4F6;
    margin: ${pxToRem(10)} 0 ${pxToRem(14)} 0;
`;

export const Error = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    color: #FF8088;
    margin: ${pxToRem(5)} 0 ${pxToRem(2)} 0;
`;

export const StyledTitle = styled.p`
    font-family: 'Neo Sans Arabic';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(24)};
    line-height: ${pxToRem(32)};
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
`;

export const StyledSubTitle = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    text-align: center;
    color: #BDC7D2;
    margin-top: ${pxToRem(10)};
`;

export const VerifyButton = styled.button`
    position: relative;
    height: ${pxToRem(44)};
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    background: #263259;
    border: none;
    outline: none;
    border-radius: ${pxToRem(24)};

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #8D97B9;
    pointer-events: none;
    ${mediaQuery('min', 'sm')} {
        bottom: 10px;
    }
    ${props => props.enabled === true && css`
        color: #FFFFFF;
        pointer-events: all;
    `}
`;

export const FixedHBox = styled(Box)`
  height: 50px
`;