import React from 'react';

import { Header } from 'components/global/header/header';
import { OuterContainer } from 'components/global/container.styled';
import { Route, Routes } from 'react-router-dom';

import { routePrivateConfig } from './config';

export const AppRoutes = () => {
  return (

      <Routes>
        {routePrivateConfig.map(({ name, path, component: Page }) => (
          <Route
            key={name}
            path={path}
            element={
              <OuterContainer>
                <Header />
                <Page />
              </OuterContainer>
            }
          />
        ))}

      </Routes>

  );
};
