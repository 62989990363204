import * as yup from 'yup';

export const Validation = {
  otpCode: yup
    .string()
    .required('Please enter correct OTP')
    .matches(/^[0-9]*$/, 'Can only include numbers.')
    .min(6, 'Minumun 6 characters')
    .max(6, 'Maximum 6 characters'),
  cpr: yup
    .string()
    .required('Please enter your CPR number')
    .matches(/^[0-9]*$/, 'Can only include numbers.')
    .min(9, 'Minimum 9 digits')
    .max(9, 'Maximum 9 digits'),
  accounts: yup
    .array()
    .min(1)
    .of(
      yup.object({
          id: yup.string(),
          productName: yup.string(),
          accountNumber: yup.string()
		  })
    )
    .required('Account selection is needed'),
  requestToken: yup
    .string()
    .required('RequestToken is necessary to send a consent approval request'),
  interactionID: yup
    .string()
    .required('Interaction ID is necessary to send a consent approval request')
}
