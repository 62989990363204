import React, { useEffect } from 'react';
import { Flex, Grid } from '@tarabutgateway/component-library';
import ILA from 'assets/images/transition/ila-logo.svg';
import ELLIPSE from 'assets/images/transition/ellipse.svg';
import TG from 'assets/images/transition/tg-logo.svg';

import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import {
    StyledTitle,
    StyledSubTitle,
    Container,
    Wrapper,
    IMGWrap,
    LogoContainer} from './transition.styled';
import { LocalStorageProvider } from 'shared/local-storage-provider';
import { AuthService } from '../../../shared/service/auth-service';

export const TransitionPage = () => {
    const { state } = useLocation();
    useEffect(() => {
        // Delete the request token from localstorage
        AuthService.logout();
        // Load the URL after 1.5 seconds
        if (state?.response != null) {
            console.log("Redirect URL", state.response)
            const redirectTo = "&status=" + state?.status;
            const redirectURL = state.response;
            setTimeout(() => {
                window.location.replace(redirectURL+redirectTo);
            }, 1500);
        }
    }
    );
    return (
        <>
        <Container>
            <Wrapper>
                <Grid displayFlex flexDirection="row">
                    <Grid.Item span={12} bpSm={12} bpLg={12}>
                        <StyledTitle>You are being redirected back</StyledTitle>
                    </Grid.Item>
                    <Grid.Item span={12} bpSm={12} bpLg={12}>
                        <StyledSubTitle>You will be redirected back to (TPP)</StyledSubTitle>
                    </Grid.Item>
                </Grid>
                <LogoContainer align="center" justify="center" fullWidth>
                    <IMGWrap src={ILA} alt="Ila Bank Logo"/>
                    <IMGWrap wider='true' src={ELLIPSE} alt="ellipse"/>
                    <IMGWrap src={TG} alt="TG logo"/>
                </LogoContainer>
            </Wrapper>
        </Container>
    </>
    );
};