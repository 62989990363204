import styled, { css } from 'styled-components';

import { Flex, Box, pxToRem, mediaQuery } from '@tarabutgateway/component-library';

export const Container = styled.div`
    display: grid;
    justify-content: center;
`;

export const Wrapper = styled.div`
    display: flex;
    height: 54vh;
    flex-flow: row wrap;
    flex-direction: column;
    padding-left: ${pxToRem(5)};
    padding-right: ${pxToRem(5)};
`;

export const TitleWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(12,1fr);
    gap: var(--spacing-2);
    padding: ${pxToRem(20)} 0 ${pxToRem(5)} ${pxToRem(15)};
`;

export const SubTitleWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(12,1fr);
    gap: var(--spacing-2);
    padding: 0 0 5px 15px;
`;

export const StyledBox = styled(Box)`
    border-radius: ${pxToRem(8)};
    overflow-y: auto;
    justify-content: left;
    align-items: center;

    ::-webkit-scrollbar, ::-moz-scrollbar, ::-ms-scrollbar {
        width: 0.5px;
    }
`;

// For personal accounts

export const StyledDiv = styled.div`
    background: #314072;
    box-shadow: 0px 0px ${pxToRem(8)} rgba(0, 0, 0, 0.05);
    border-radius: ${pxToRem(8)};
    width: ${pxToRem(327)};
    height: ${pxToRem(88)};
    margin-top: 8px;
    margin-bottom: 12px;
`;

export const StyledAccountName = styled.div`
    font-family: 'Neo Sans Arabic';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
    color: #FFFFFF;
`;

export const StyledIBAN = styled.div`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: ${pxToRem(16)};
    color: #A6AEC9;
`;

export const Error = styled.p`
    font-size: 14px;
    color: red;
`;

export const StyledTitle = styled.p`
    font-family: 'Neo Sans Arabic';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(24)};
    line-height: ${pxToRem(32)};
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
`;

export const StyledSubTitle = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    text-align: center;
    color: #BDC7D2;
    margin-top: ${pxToRem(10)};
`;

export const FooterContainer = styled.span`
    padding: 0 25px 0 25px;
`;

export const ProceedButton = styled.button`
    position: relative;
    width: 100%;
    height: ${pxToRem(44)};
    background: #263259;
    border: none;
    outline: none;
    border-radius: ${pxToRem(24)};

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #8D97B9;
    pointer-events: none;
    margin-top: 40px;
    ${mediaQuery('min', 'sm')} {
        bottom: 10px;
    }
    ${props => props.enabled === 'true' && css`
        pointer-events: all;
        color: #ffffff
    `}
`;

export const DeclineButton = styled.button`
    position: relative;
    width: 100%;
    height: ${pxToRem(44)};

    border: none;
    outline: none;
    background: transparent;
    border: 2px solid #263259;
    border-radius: ${pxToRem(24)};

    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;

`;

export const MiscInfo = styled.p`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #BDC7D2;
`;

export const StyledFlex = styled(Flex)`
    padding: 24px 15px 0 15px;
`;

export const LogoContainer = styled.span`
    text-align: end;
    display: contents;
`;

export const LogoSrc = styled.img`
    padding-right: 15px
`;