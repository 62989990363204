import { OTPVerification, AccountsPage, TransitionPage } from 'components/pages';

import PropTypes from 'prop-types';

import { RoutePath } from '../route-path';
import { RouteConfig } from '../types';

export const routePrivateConfig = [
  {
    name: 'Verify OTP',
    path: RoutePath.verify,
    component: OTPVerification,
  },
  {
    name: 'Accounts',
    path: RoutePath.accounts,
    component: AccountsPage,
  },
  {
    name: 'Transition',
    path: RoutePath.transition,
    component: TransitionPage,
  }
];

routePrivateConfig.propTypes = PropTypes.arrayOf(RouteConfig)