import React, { useMemo, useState, useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import toast from 'react-hot-toast';
import { Grid } from '@tarabutgateway/component-library';
import { LocalStorageProvider } from 'shared/local-storage-provider';
import { AuthService } from 'shared/service/auth-service'
import { Validation } from 'utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader } from 'components/global/loader';
import { RoutePath } from 'routes/route-path';
import {
  LoginField,
  Error,
  StyledTitle,
  StyledSubTitle,
  Container,
  Wrapper,
  Placeholder,
  DirectionsDetails,
  DirectionsTitle,
  VerifyButton,
  FixedHBox } from './otp.styled';

export const OTPVerification = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const validationSchema = yup.object({
      otp: Validation.otpCode,
      cpr: Validation.cpr,
      requestToken: Validation.requestToken
  });

  let query = useQuery();

  //save requestToken in local storage
  LocalStorageProvider.setAccessToken(query.get("requestToken"));

  const {
      handleSubmit,
      register,
      setValue,
      formState: {errors, isValid, isSubmitting}
    } = useForm({
      defaultValues: {
        cpr: "",
        otp: "",
        requestToken: ""
      },
      resolver: async (data, context, options) => {
        // for debugging validation schema
        // console.log('validation result', await yupResolver(validationSchema)(data, context, options))
        return yupResolver(validationSchema)(data, context, options)
      }
    });

  useEffect(()=>{
    setValue("requestToken", LocalStorageProvider.getAccessToken(), {
        shouldValidate: true,
        shouldDirty: true
    })
  }, []);

  const onSubmit = async (data) => {
      const response = await AuthService.verify_otp(data);

      if (response) {
        if (response.hasOwnProperty("status")) {
          setErrorMessage(response.message)
          toast.error("OTP Verification Failed");
        } else {
          toast.success("OTP Verification Successful");
          navigate(RoutePath.accounts, { state: { response } })
        }
      }
    };
  return (
    <>
      {isSubmitting && <Loader />}
      <Container>
        <Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid displayFlex flexDirection="row">
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <StyledTitle>Please enter your CPR and the OTP code</StyledTitle>
            </Grid.Item>
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <StyledSubTitle>Open ila Bank app to get the OTP code</StyledSubTitle>
            </Grid.Item>
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <Placeholder>Enter CPR</Placeholder>
            </Grid.Item>
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <FixedHBox>
                <LoginField error={errorMessage || errors.cpr?.message ? true: false}
                            { ...register('cpr') }
                            />
                  {errors.cpr?.message && (
                      <Error>{errors.cpr?.message}</Error>
                  )}
                  {errorMessage && (
                      <Error>{errorMessage}</Error>
                  )}
              </FixedHBox>
            </Grid.Item>
            <br/>
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <Placeholder>Enter OTP code</Placeholder>
            </Grid.Item>
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <FixedHBox>
              <LoginField error={errorMessage || errors.otp?.message ? true: false}
                          { ...register('otp') }
                          />
                {errors.otp?.message && (
                    <Error>{errors.otp?.message}</Error>
                )}
                {errorMessage && (
                    <Error>{errorMessage}</Error>
                )}
              </FixedHBox>
            </Grid.Item>

            <br/>
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <DirectionsTitle>How to find the OTP code?</DirectionsTitle>
              <DirectionsDetails>
                1. Open the ila Bank app<br/>
                2. Tap on "Profile" in the bottom tab and then &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;tap on "My Security"<br/>
                3. Tap on “Generate OTP Code”<br/>
                4. Copy the OTP code that you get and paste it &nbsp; &nbsp; &nbsp; &nbsp; in the field above<br/>
                <br/>
                You will have 30 seconds to enter the generated OTP code, if it expires please follow the steps above and try again.
              </DirectionsDetails>
            </Grid.Item>
            <Grid.Item span={12} bpSm={12} bpLg={12}>
              <VerifyButton enabled={isValid} type="submit">Verify</VerifyButton>
            </Grid.Item>
          </Grid>
          </form>
        </Wrapper>
      </Container>
    </>
  );
};
