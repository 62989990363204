import React from 'react';

import { AppRoutes } from 'routes';
import { GlobalFonts } from 'assets/fonts/fonts';
import { Toaster } from 'react-hot-toast';

export const App = () => {
  return (
    <>
      <GlobalFonts />
      <AppRoutes />
      <Toaster />
    </>
  );
};
