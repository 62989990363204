import { createGlobalStyle } from 'styled-components';

import NeoSans from 'url:./NeoSansArabic.ttf';
import SFPro from 'url:./SF-Pro-Text-Regular.otf';

export const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Neo Sans Arabic';
        src: url(${NeoSans}) format('truetype');
    };
    @font-face {
        font-family: 'SF Pro Text';
        src: url(${SFPro}) format('opentype');
    }
`;